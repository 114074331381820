:root {
  // Font
  --font-base: "Avenir Next Cyr", sans-serif;
  --font-second: "Avenir Next Cyr", sans-serif;
  --font-size-base: 1rem;

  // Responsive scales
  --scale-1: 10px;
  --scale-2: 14px;
  --scale-3: 16px;
  --scale-4: 18px;
  --scale-5: 20px;
  --scale-6: 24px;
  --scale-7: 28px;
  --scale-8: 30px;
  --scale-9: 32px;
  --scale-10: 34px;
  --scale-11: 36px;
  --scale-12: 38px;

  // Font sizes
  --font-size-xxxl: 3rem;
  --font-size-xxl: 2.2rem;
  --font-size-xl: 1.6rem;
  --font-size-l: 1.125rem;
  --font-size-m: var(--font-size-base);
  --font-size-s: 0.875rem;
  --font-size-xs: 0.75rem;
  --font-size-xxs: 0.625rem;
  --font-size-xxxs: 0.5rem;

  // Font weight
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  // Headers
  --font-size-h1: var(--font-size-xxxl);
  --font-size-h2: var(--font-size-xxl);
  --font-size-h3: var(--font-size-xxl);
  --font-size-h4: var(--font-size-xl);
  --font-size-h5: var(--font-size-l);
  --font-size-h6: var(--font-size-m);

  // Line height
  --line-height-medium: 1.4;
  --line-height-header: 1.2;

  --line-height-h1: var(--line-height-header);
  --line-height-h2: var(--line-height-header);
  --line-height-h3: var(--line-height-header);
  --line-height-h4: var(--line-height-header);

  // Base colors
  --color-black: #000000;
  --color-white: #ffffff;

  --color-contrast-0: #ffffff;
  --color-contrast-2: #f2f2f6;
  --color-contrast-5: #ededf2;
  --color-contrast-10: #e1e1e6;
  --color-contrast-15: #d4d4d9;
  --color-contrast-20: #c8c8cc;
  --color-contrast-30: #afafb3;
  --color-contrast-40: #969699;
  --color-contrast-50: #7d7d80;
  --color-contrast-60: #646466;
  --color-contrast-70: #4b4b4c;
  --color-contrast-80: #323233;
  --color-contrast-90: #19191a;
  --color-contrast-100: #000000;

  // Primary colors
  --color-primary: #1bd0c5;
  --color-warning: #FFE500;
  --color-critical: #e13c5a;

  // State colors
  --color-success: #11d5a9;
  --color-error: var(--color-critical);

  --color-link: #fce98e;
  --color-link-hover: var(--color-link);
  --color-link-active: var(--color-link);

  --color-focus-outline: var(--color-contrast-0);

  // theme colors
  --color-accent: #FF4298;

  --color-text: #484444;
  --color-text-contrast-1: #fff;
  --color-text-contrast-2: #99A0AC;
  --color-text-error: #FF278F;

  --color-background: #282A2E;
  --color-background-second: #42464E;
  --color-background-contrast: var(--color-white);

  --color-button-text: var(--color-text-contrast-1);

  --color-button-regular: #656C79;
  --color-button-regular-active: #4F545F;
  --color-button-regular-hover: #7B828E;

  --color-button-primary: #DD2A7C;
  --color-button-primary-active: #A82762;
  --color-button-primary-hover: #F1378C;

  --color-button-accent: var(--color-button-primary);
  --color-button-accent-active: var(--color-button-primary-active);
  --color-button-accent-hover: var(--color-button-primary-hover);

  --color-button-action: #6F95F5;
  --color-button-action-active: #4574ED;
  --color-button-action-hover: #6F95F5;

  --color-button-focus: var(--color-focus-outline);
  --color-button-focus-active: var(--color-focus-outline);
  --color-button-focus-hover: var(--color-focus-outline);
  --color-button-focus-text: var(--color-text);

  --color-button-progress: #6aa0e2;
  --color-button-progress-second: #0d3c8e;
  --color-button-progress-active: #0d3c8e;
  --color-button-progress-hover: #0551ad;

  --color-input: #D7DDEA;
  --color-input-text: #2A2C2F;

  --color-slider-track-active: #fff;
  --color-slider-track-inactive: #74b5f9;
  --color-slider-handle: var(--color-button-regular);
  --color-slider-handle-hover: var(--color-focus-outline);
  --color-slider-handle-focus: var(--color-focus-outline);
  --color-slider-tick-marks: #74b5f9;

  --color-bottom-sheet-panel: #fff;
  --color-bottom-sheet-text: #191A1C;
  --color-bottom-sheet-handle: #D9D9D9;

  // game theme
  --color-background-aside-connect: #ffffff;
  --color-background-aside-results: #0067d8;

  --color-progress-bar: #ffffff;
  --color-progress-bar-active: #74b5f9;

  --color-timer: #83c624;
  --color-timer-text: var(--color-text);
  --color-timer-active: #ffb000;
  --color-timer-warning: #ff4893;

  --color-player-answer-correct-background: var(--color-background-player-answer-correct, #81c618);
  --color-player-answer-correct-background-second: var(--color-background-player-answer-correct-second, #008b43);
  --color-player-answer-correct-text: var(--color-text);

  --color-player-answer-wrong-background: var(--color-background-player-answer-wrong, #ff4893);
  --color-player-answer-wrong-background-second: var(--color-background-player-answer-wrong-second, #b60a74);
  --color-player-answer-wrong-text: var(--color-text);

  --color-player-win-background: var(--color-background-player-win, #81c618);
  --color-player-win-background-second: var(--color-background-player-win-second, #008b43);
  --color-player-win-text: var(--color-text);

  --color-player-lose-background: var(--color-background-player-lose, #ff4893);
  --color-player-lose-background-second: var(--color-background-player-lose-second, #b60a74);
  --color-player-lose-text: var(--color-text);

  --color-results-question: #0083ff;
  --color-results-question-circle: rgba(255, 255, 255, 0.85);
  --color-results-avatar-circle: rgba(255, 255, 255, 0.85);
  --color-results-background-circle-1: rgba(0, 128, 255, 0.3);
  --color-results-background-circle-2: #0080ff;
  --color-results-score-circle: #ffb000;
  --color-results-position-circle: #0083ff;
}
