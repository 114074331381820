.mat-mdc-dialog-container {
  max-width: 50rem;

  .mat-mdc-dialog-surface {
    padding: 2rem;
  }
}

mat-snack-bar-container {
  --mdc-snackbar-container-color: var(--color-background-second);
  --mdc-snackbar-container-shape: .7rem;
  --mdc-snackbar-supporting-text-color: var(--color-text-contrast-1);
  --mdc-snackbar-supporting-text-size: var(--font-size-xs);
  text-align: center;

  &.alert {
    --mdc-snackbar-container-color: var(--color-critical);
    --mdc-snackbar-supporting-text-color: var(--color-white);
  }
}

.catalog-dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: var(--color-background-second);
    --mdc-dialog-container-shape: 1rem;
    --mdc-dialog-supporting-text-color: var(--color-text-contrast-1);
    max-width: 35rem;
  }

  .mat-mdc-dialog-surface {
    text-align: center;
    padding: 2rem 3rem 3rem;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel.fullscreen-dialog {
  max-width: none;
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 0;
    background: var(--color-background, #282A2E);
  }
}
